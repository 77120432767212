export const submitButtonText = () => ["Submit", "Enviar"];
export const submittedMessageText = () => ["submitted!", "¡enviado!"];
export const continueSurveyText = () => ["Continue", "Continuar"];
export const nextQuestionText = () => ["Next", "Siguiente"];
export const previousQuestionText = () => ["Back", "Atrás"];
export const cancelDialogText = () => ["Cancel", "Cancelar"];
export const okDialogText = () => ["Ok", "Aceptar"];
export const selectionLimitText = (...args) => [
  `Select up to ${args[0]}`,
  `Seleccione hasta ${args[0]}`,
];
export const maxSelectionPromptText = (...args) => [
  `Select up to ${args[0]}`,
  `Seleccione hasta ${args[0]}`,
];
export const selectAllText = () => [
  "Select all that apply",
  "Seleccione todas las opciones que correspondan.",
];
export const selectBestAnswerText = () => [
  "Select the best answer",
  "Seleccione la mejor respuesta",
];
export const timeFormat12HourText = () => ["HH:MM AM/PM", "HH:MM a. m./p. m."];
export const timeFormat24HourText = () => [
  "HH:MM (24-hour format)",
  "HH:MM (formato de 24 horas)",
];
export const dateFormatDDMMMYYYY = () => ["DD/MMM/YYYY", "DD/MMM/AAAA"];
export const dateFormatMMDDYYYY = () => ["MM/DD/YYYY", "DD/MM/AAAA"];
export const dateFormatDDMMYYYY = () => ["DD/MM/YYYY", "DD/MM/AAAA"];
export const dateFormatYYYYMMDD = () => ["YYYY/MM/DD", "AAAA/MM/DD"];
export const freeResponseText = () => [
  "Type your response",
  "Escriba su respuesta",
];
export const selectResponseDropdownText = () => [
  "Select your response",
  "Seleccione su respuesta",
];
export const charLimitText = (...args) => [
  `Maximum ${args[0]} characters`,
  `Máximo ${args[0]} caracteres`,
];
export const lettersOnlyText = () => [
  "Please use only letters. No numbers or symbols.",
  "Utilice solo letras. No use números ni símbolos.",
];
export const numberGreaterThanText = (...args) => [
  `Please enter a number greater than ${args[0]}`,
  `Introduzca un número mayor que ${args[0]}.`,
];
export const numberLessThanText = (...args) => [
  `Please enter a number less than ${args[0]}`,
  `Introduzca un número menor a ${args[0]}.`,
];
export const numberRangeText = (...args) => [
  `Please enter a number between ${args[0]} and ${args[1]}`,
  `Introduzca un número entre ${args[0]} y ${args[1]}`,
];
export const dateFormatText = () => [
  "Please enter a valid date",
  "Introduzca un número válido",
];
export const dateAfterText = (...args) => [
  `Please enter a date after ${args[0]}`,
  `Introduzca una fecha posterior a ${args[0]}`,
];
export const dateBeforeText = (...args) => [
  `Please enter a date before ${args[0]}`,
  `Introduzca una fecha anterior a ${args[0]}`,
];
export const dateRangeText = (...args) => [
  `Please enter a date between ${args[0]} and ${args[1]}`,
  `Introduzca una fecha entre ${args[0]} y ${args[1]}`,
];
export const timeFormatText = () => [
  "Please enter a valid time",
  "Introduzca una hora válida",
];
export const multiSelectInstructionText = () => [
  "Select all that apply",
  "Seleccione todas las opciones que correspondan.",
];
export const multiSelectMinLimitInstructionText = (...args) => [
  `Select at least ${args[0]} responses`,
  `Seleccione al menos ${args[0]} respuestas`,
];
export const multiSelectMaxLimitInstructionText = (...args) => [
  `Select up to ${args[0]} responses`,
  `Seleccione hasta ${args[0]} respuestas`,
];
export const multiSelectRangeLimitInstructionText = (...args) => [
  `Select ${args[0]} to ${args[1]} responses`,
  `Seleccione ${args[0]} para ${args[1]} las respuestas`,
];
export const singleSelectInstructionText = () => [
  "Select the most applicable response",
  "Seleccione la respuesta más coincida",
];
export const freeResponseInstructionText = () => [
  "Enter response",
  "Introduzca la respuesta",
];
export const startTaskText = () => ["Start", "Iniciar"];
export const introPageText = () => ["Continue", "Continuar"];
export const outroPageText = () => ["Done", "Listo"];
export const dateText = () => ["Date", "Fecha"];
export const timeText = () => ["Time", "Hora"];
export const dateLabel = () => ["Date", "Fecha"];
export const timeLabel = () => ["Time", "Hora"];
export const emailLabel = () => ["Email", "Correo electrónico"];
export const nameFirstNameLabel = () => ["First name", "Nombre"];
export const nameMiddleNameLabel = () => ["Middle name", "Segundo nombre"];
export const nameLastNameLabel = () => ["Last name", "Apellido"];
export const dateDisplayCharYear = () => ["y", "y"];
export const dateDisplayCharMonth = () => ["m", "m"];
export const dateDisplayCharDay = () => ["d", "d"];
export const timeDisplayCharHour = () => ["h", "h"];
export const timeDisplayCharMinute = () => ["m", "m"];
export const timeDisplayCharMeridiem = () => ["a", "a"];
export const unknownErrorMessage = () => [
  "An unknown error occurred.",
  "Ocurrió un error desconocido.",
];
export const loadFailureMessage = () => [
  "Survey failed to load.",
  "La encuesta no se ha podido cargar.",
];
export const unsupportedQuestionTypeMessage = () => [
  "Unsupported question type.",
  "Tipo de pregunta no compatible.",
];
export const choiceQuestionMinSelectionValidationMessage = (...args) => [
  `Please select at least ${args[0]} option(s).`,
  `Seleccione al menos ${args[0]} opción(es).`,
];
export const choiceQuestionMaxSelectionValidationMessage = (...args) => [
  `Please select at most ${args[0]} option(s).`,
  `Seleccione como máximo ${args[0]} opción(es).`,
];
export const dateQuestionMinDateValidationMessage = (...args) => [
  `Date must be on or after ${args[0]}.`,
  `La fecha debe ser igual o posterior a ${args[0]}.`,
];
export const dateQuestionMaxDateValidationMessage = (...args) => [
  `Date must be on or before ${args[0]}.`,
  `La fecha debe ser igual o anterior a ${args[0]}.`,
];
export const dateQuestionInvalidDateValidationMessage = () => [
  "Please enter a valid date.",
  "Introduzca una fecha válida.",
];
export const dateQuestionInvalidYearValidationMessage = () => [
  "Please enter a valid year.",
  "Introduzca un año válido.",
];
export const dateQuestionInvalidMonthValidationMessage = () => [
  "Please enter a valid month.",
  "Introduzca un mes válido.",
];
export const dateQuestionInvalidDayValidationMessage = () => [
  "Please enter a valid day.",
  "Introduzca un día válido.",
];
export const dateQuestionMissingMonthValidationMessage = () => [
  "Please enter the month.",
  "Introduzca el mes.",
];
export const dateQuestionMissingDayValidationMessage = () => [
  "Please enter the day.",
  "Introduzca el día.",
];
export const numericQuestionInvalidNumberValidationMessage = () => [
  "Value is not a valid number.",
  "El valor no es un número válido.",
];
export const numericQuestionMinNumberValidationMessage = (...args) => [
  `Value must be at least ${args[0]}.`,
  `El valor debe ser al menos ${args[0]}.`,
];
export const numericQuestionMaxNumberValidationMessage = (...args) => [
  `Value must be at most ${args[0]}.`,
  `El valor debe ser como máximo ${args[0]}.`,
];
export const numericQuestionMaxDecimalPlacesValidationMessage = (...args) => [
  `Value must have at most ${args[0]} decimal place(s).`,
  `El valor debe tener como máximo ${args[0]} decimal(es).`,
];
export const textQuestionMinLengthValidationMessage = (...args) => [
  `Value must be at least ${args[0]} character(s).`,
  `El valor debe tener al menos ${args[0]} caracteres.`,
];
export const textQuestionMaxLengthValidationMessage = (...args) => [
  `Value must be at most ${args[0]} character(s).`,
  `El valor debe tener máximo ${args[0]} carácter(es).`,
];
export const multiSelectMinLimitValidationMessage = (...args) => [
  `Please select at least ${args[0]} option(s).`,
  `Seleccione al menos ${args[0]} opción(es).`,
];
export const multiSelectMaxLimitValidationMessage = (...args) => [
  `Please select at most ${args[0]} option(s).`,
  `Seleccione como máximo ${args[0]} opción(es).`,
];
export const multiSelectRangeLimitValidationMessage = (...args) => [
  `Please select between ${args[0]} and ${args[1]} option(s).`,
  `Seleccione entre ${args[0]} y ${args[1]} opción(es).`,
];
export const multiSelectSingletonRangeLimitValidationMessage = (...args) => [
  `Please select exactly ${args[0]} option(s).`,
  `Seleccione exactamente ${args[0]} opción(es).`,
];
export const dateInvalidValidationMessage = () => [
  "Please enter a valid date.",
  "Introduzca una fecha válida.",
];
export const dateMinLimitValidationMessage = (...args) => [
  `Please enter a date on or after ${args[0]}.`,
  `Introduzca una fecha el o después de ${args[0]}.`,
];
export const dateMaxLimitValidationMessage = (...args) => [
  `Please enter a date on or before ${args[0]}.`,
  `Introduzca una fecha el o antes de ${args[0]}.`,
];
export const dateRangeLimitValidationMessage = (...args) => [
  `Please enter a date between ${args[0]} and ${args[1]}.`,
  `Introduzca una fecha entre ${args[0]} y ${args[1]}.`,
];
export const partialDateInvalidYearValidationMessage = () => [
  "Please enter a valid year.",
  "Introduzca un año válido.",
];
export const partialDateInvalidMonthValidationMessage = () => [
  "Please enter a valid month.",
  "Introduzca un mes válido.",
];
export const partialDateInvalidDayValidationMessage = () => [
  "Please enter a valid day.",
  "Introduzca un día válido.",
];
export const partialDateMissingMonthValidationMessage = () => [
  "Please enter the month.",
  "Introduzca el mes.",
];
export const partialDateMissingDayValidationMessage = () => [
  "Please enter the day.",
  "Introduzca el día.",
];
export const timeInvalidValidationMessage = () => [
  "Please enter a valid time.",
  "Introduzca una hora válida.",
];
export const timeMinLimitValidationMessage = (...args) => [
  `Please enter a time on or after ${args[0]}.`,
  `Introduzca una hora el o después de ${args[0]}.`,
];
export const timeMaxLimitValidationMessage = (...args) => [
  `Please enter a time on or before ${args[0]}.`,
  `Introduzca una hora el o antes de ${args[0]}.`,
];
export const timeRangeLimitValidationMessage = (...args) => [
  `Please enter a time between ${args[0]} and ${args[1]}.`,
  `Introduzca una hora entre ${args[0]} y ${args[1]}.`,
];
export const numericInvalidValidationMessage = () => [
  "Please enter a valid number.",
  "Introduzca un número válido.",
];
export const numericMinLimitValidationMessage = (...args) => [
  `Please enter a number greater than or equal to ${args[0]}.`,
  `Introduzca un número mayor o igual que ${args[0]}.`,
];
export const numericMaxLimitValidationMessage = (...args) => [
  `Please enter a number less than or equal to ${args[0]}.`,
  `Introduzca un número menor o igual que ${args[0]}.`,
];
export const numericRangeLimitValidationMessage = (...args) => [
  `Please enter a number between ${args[0]} and ${args[1]}.`,
  `Introduzca un número entre ${args[0]} y ${args[1]}.`,
];
export const numericMaxDecimalPlacesValidationMessage = (...args) => [
  `Please enter a number with at most ${args[0]} decimal place(s).`,
  `Introduzca un número con como máximo ${args[0]} decimal(es).`,
];
export const numericMissingUnitValidationMessage = () => [
  "Please select a unit.",
  "Seleccione una unidad.",
];
export const textMinLengthValidationMessage = (...args) => [
  `Please enter at least ${args[0]} character(s).`,
  `Introduzca al menos ${args[0]} carácter(es).`,
];
export const textMaxLengthValidationMessage = (...args) => [
  `Please enter at most ${args[0]} character(s).`,
  `Introduzca como máximo ${args[0]} carácter(es).`,
];
export const textRangeLengthValidationMessage = (...args) => [
  `Please enter between ${args[0]} and ${args[1]} character(s).`,
  `Introduzca entre ${args[0]} y ${args[1]} carácter(es).`,
];
export const textAlphabeticOnlyValidationMessage = () => [
  "Please use only letters—no numbers or symbols.",
  "Utilice solo letras, no números ni símbolos.",
];
export const textRegexValidationMessage = (...args) => [
  `Please enter a response that matches the pattern ${args[0]}.`,
  `Introduzca una respuesta que coincida con el patrón ${args[0]}.`,
];
export const emailInvalidValidationMessage = () => [
  "Please enter a valid email.",
  "Introduzca un correo electrónico válido.",
];
export const compoundTextMissingFieldValidationMessage = () => [
  "Please enter a response.",
  "Introduzca una respuesta.",
];
export const requiredText = () => ["Required.", "Obligatorio."];
export const exitButtonText = () => ["Exit", "Salir"];
export const otherChoiceLabelText = () => ["Other", "Otro"];
export const errorPageMainText = () => [
  "Something went wrong",
  "Algo salió mal",
];
export const errorPageTryLaterText = () => [
  "We're having a technical issue and we're trying to fix it. Please try again later.",
  "Tenemos un problema técnico y estamos tratando de resolverlo. Intente de nuevo más tarde.",
];
export const refreshButtonText = () => ["Refresh", "Actualizar"];
export const notaChoiceLabelText = () => [
  "None of the above",
  "Ninguna de las opciones anteriores",
];
export const compoundNumericMissingFieldValidationMessage = () => [
  "Please enter your response.",
  "Ingrese su respuesta.",
];
export const surveyTitleInfoLabel = () => [
  "About this survey",
  "Acerca de esta encuesta",
];
export const closeModalAccessibilityText = () => [
  "Close Modal",
  "Cerrar modal",
];
